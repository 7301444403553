import { createGraphQLClient, Platform, Stack } from '@clientos/graphql-client';
import { createGraphQLProvider } from '@clientos/graphql-client/dist/react';
import React, { useEffect } from 'react';
import { publishEvent, SecurityScreenDisplayed } from 'src/utils/analytics';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import * as T from './types';
import MainComponent from './MainComponent';
import '../styles/global.scss';

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = (props) => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  const client = createGraphQLClient({
    authTokenCallback: async () => {
      return await (props.authProvider as any).getAccessToken();
    },
    platform: Platform.WEB,
    stack: stack,
    isShellPortal: true
  });

  const GraphQLProvider = createGraphQLProvider(React);

  useEffect(() => {
    publishEvent(SecurityScreenDisplayed);
  }, []);

  return (
    <DependencyManagerProvider>
      <GraphQLProvider
        client={client}
        // eslint-disable-next-line react/no-children-prop
        children={<MainComponent {...props} />}
      />
    </DependencyManagerProvider>
  );
};

export default App;
