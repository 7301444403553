import { gql } from '@clientos/graphql-client';

const GET_USER_DETAILS = gql`
  query Query {
    account {
      user {
        email
        emailVerified
        phone
        phoneVerified
      }
    }
  }
`;

export default GET_USER_DETAILS;
