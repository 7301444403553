import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { FlagProps } from 'src/types/Props';
import { FeatureFlagsEnum } from 'src/utils/enums';

const FeatureFlagsService = (): FlagProps => {
  let loadingFlags = false;

  const {
    featureFlagValue: qualtricsSurvey,
    isLoading: isQualtricsSurveyLoading
  } = useFeatureFlags(FeatureFlagsEnum.qualtricsSurvey);

  const {
    featureFlagValue: securityListLoginDetails,
    isLoading: isSecurityListLoginDetailsLoading
  } = useFeatureFlags(FeatureFlagsEnum.securityListLoginDetails);

  const {
    featureFlagValue: securityHpIdEmail,
    isLoading: isSecurityHpIdEmailLoading
  } = useFeatureFlags(FeatureFlagsEnum.securityHPIDEmail);

  const {
    featureFlagValue: securityHpIdPhone,
    isLoading: isSecurityHpIdPhoneLoading
  } = useFeatureFlags(FeatureFlagsEnum.securityHPIDPhone);

  const {
    featureFlagValue: securityHpIdPassword,
    isLoading: isSecurityHpIdPasswordLoading
  } = useFeatureFlags(FeatureFlagsEnum.securityHPIDPassword);

  if (
    isQualtricsSurveyLoading ||
    isSecurityListLoginDetailsLoading ||
    isSecurityHpIdEmailLoading ||
    isSecurityHpIdPhoneLoading ||
    isSecurityHpIdPasswordLoading
  ) {
    loadingFlags = true;
  }

  const flags = {
    isLoadingFlags: loadingFlags,
    isQualtricsSurveyEnabled: qualtricsSurvey && !isQualtricsSurveyLoading,
    isSecurityListLoginDetailsEnabled:
      securityListLoginDetails && !isSecurityListLoginDetailsLoading,
    isSecurityHpIdEmailEnabled:
      securityHpIdEmail && !isSecurityHpIdEmailLoading,
    isSecurityHpIdPhoneEnabled:
      securityHpIdPhone && !isSecurityHpIdPhoneLoading,
    isSecurityHpIdPasswordEnabled:
      securityHpIdPassword && !isSecurityHpIdPasswordLoading
  } as FlagProps;

  return flags;
};

export const getFeatureFlagsValues = (): FlagProps => {
  return FeatureFlagsService();
};
