import { useQuery } from '@clientos/graphql-client/dist/react';
import {
  Card,
  Loading,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { ColumnProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardColumns';
import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';
import React from 'react';
import ErrorBoundary from 'src/App/ErrorBoundary';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_USER_DETAILS from 'src/graphql/getUserDetails';
import { Label, Row, Value, ValueContent } from 'src/styles/styles';
import {
  LoginDetailsError,
  ManageEmailHyperLinkClicked,
  ManagePhoneHyperLinkClicked,
  ChangePasswordHyperLinkClicked,
  ManageLoginDetailsHyperLinkClicked,
  ChangePasswordControlButtonClicked,
  ManageEmailControlButtonClicked,
  ManagePhoneControlButtonClicked
} from 'src/utils/analytics';
import { FormTypesEnum } from 'src/utils/enums';
import { URLS } from 'src/utils/urls';
import Verified from '../Verified';
import { FlagProps } from 'src/types/Props';
import { RowContent } from './styles';

interface ValueColumnProps {
  value: string;
  verified?: boolean | null;
}
interface RowDataProps {
  label: string;
  column: ValueColumnProps;
}

const ValueColumn = ({ value, verified }: ValueColumnProps) => {
  return (
    <Value>
      <ValueContent>{value}</ValueContent>
      {verified != null && <Verified verified={verified} />}
    </Value>
  );
};

const RowData = ({ label, column }: RowDataProps) => {
  return (
    <Row>
      <Label className="caption">{label}</Label>
      <ValueColumn
        value={column.value}
        verified={column.verified}
      />
    </Row>
  );
};

interface LoginDetailsProps {
  flags: FlagProps;
}

const LoginDetails = ({ flags }: LoginDetailsProps): React.JSX.Element => {
  const { loading, error, data } = useQuery(GET_USER_DETAILS);
  const { translate } = useDependencyManagerContext();
  const { email, emailVerified, phone, phoneVerified } =
    data?.account?.user || {};

  const showSecurityListLoginDetails =
    flags.isSecurityListLoginDetailsEnabled && !loading;

  const LoginDetailsTable = () => {
    const title = {
      content: translate('security.loginDetails', 'Login details'),
      type: ROW_TYPE.EXTERNAL,
      link: {
        url: URLS.HpIdSecurity,
        dataTestId: 'ManageLoginDetailsLink'
      },
      analyticsEvent: ManageLoginDetailsHyperLinkClicked
    };

    const content = (
      <RowContent>
        <RowData
          label={translate('profile.email', 'Email')}
          column={{
            value: email || translate('profile.noEmail', 'No email'),
            verified: email ? emailVerified : null
          }}
        />
        <RowData
          label={translate('profile.phone', 'Phone')}
          column={{
            value: phone || translate('profile.noPhone', 'No phone'),
            verified: phone ? phoneVerified : null
          }}
        />
        <RowData
          label={translate('security.currentPassword', 'Current password')}
          column={{
            value: '*************'
          }}
          data-testid="default-password-row"
        />
      </RowContent>
    );

    return (
      <Card
        title={title}
        enableRowsColorChange={false}
        childrenStyle={{ align: 'left', marginTop: '8px' }}
      >
        {data?.account?.user && content}
      </Card>
    );
  };

  const LoginDetailsList = () => {
    const emailColumns: ColumnProps[] = [];
    const phoneColums: ColumnProps[] = [];

    emailColumns.push({
      value: translate('profile.email', 'Email').toString()
    });

    emailColumns.push(
      email
        ? {
            value: (
              <ValueColumn
                value={email}
                verified={emailVerified}
              />
            )
          }
        : {
            value: translate('profile.noEmail', 'No email').toString()
          }
    );

    phoneColums.push({
      value: translate('profile.phone', 'Phone').toString()
    });

    phoneColums.push(
      phone
        ? {
            value: (
              <ValueColumn
                value={phone}
                verified={phoneVerified}
              />
            )
          }
        : {
            value: translate('profile.noPhone', 'No phone').toString()
          }
    );

    const rows = [
      {
        index: 0,
        content: emailColumns,
        type: flags.isSecurityHpIdEmailEnabled
          ? ROW_TYPE.INTERNAL
          : ROW_TYPE.EXTERNAL,
        link: {
          url: URLS.HpIdSecurity,
          pathname: `/security/${FormTypesEnum.Email}`,
          dataTestId: 'ManageEmailLink'
        },
        analyticsEvent: flags.isSecurityHpIdEmailEnabled
          ? ManageEmailControlButtonClicked
          : ManageEmailHyperLinkClicked
      },
      {
        index: 1,
        content: phoneColums,
        type: flags.isSecurityHpIdPhoneEnabled
          ? ROW_TYPE.INTERNAL
          : ROW_TYPE.EXTERNAL,
        link: {
          url: URLS.HpIdSecurity,
          pathname: `/security/${FormTypesEnum.Phone}`,
          dataTestId: 'ManagePhoneLink'
        },
        analyticsEvent: flags.isSecurityHpIdPhoneEnabled
          ? ManagePhoneControlButtonClicked
          : ManagePhoneHyperLinkClicked
      },
      {
        index: 2,
        content: [
          {
            value: translate(
              'security.currentPassword',
              'Current password'
            ).toString()
          },
          {
            value: '*************'
          }
        ],
        type: flags.isSecurityHpIdPasswordEnabled
          ? ROW_TYPE.INTERNAL
          : ROW_TYPE.EXTERNAL,
        link: {
          url: URLS.HpIdSecurity,
          pathname: `/security/${FormTypesEnum.Password}`,
          dataTestId: 'ChangePasswordLink'
        },
        analyticsEvent: flags.isSecurityHpIdPasswordEnabled
          ? ChangePasswordControlButtonClicked
          : ChangePasswordHyperLinkClicked
      }
    ] as CardRowProps[];

    const titleContent = {
      content: translate('security.loginDetails', 'Login details')
    };

    return (
      <Card
        title={titleContent}
        rows={rows}
      />
    );
  };

  if (loading) {
    return (
      <Card
        enableRowsColorChange={false}
        childrenStyle={{ align: 'center', marginTop: '16px' }}
      >
        <Loading testid="loading-section" />
      </Card>
    );
  }

  if (error) {
    const title = {
      content: translate('security.loginDetails', 'Login details')
    };

    return (
      <Card
        title={title}
        enableRowsColorChange={false}
        childrenStyle={{ align: 'center', marginTop: '16px' }}
        data-testid="error-section"
      >
        <ErrorBoundary analyticsEvent={LoginDetailsError(error.message)} />
      </Card>
    );
  }

  return showSecurityListLoginDetails ? (
    <LoginDetailsList />
  ) : (
    <LoginDetailsTable />
  );
};

export default LoginDetails;
