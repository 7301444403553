import { tokens } from '@veneer/tokens';
import { MainTheme } from 'src/styles/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: ${tokens.layout.size5};
  max-width: 1220px;
  margin: ${tokens.layout.size5} 40px;
  width: -webkit-fill-available;

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    margin: ${tokens.layout.size4};
    gap: ${tokens.layout.size4};
  }

  @media (${MainTheme.mediaQueries.small}) {
    margin: ${tokens.layout.size4};
  }

  @media (${MainTheme.mediaQueries.medium}) {
    margin: ${tokens.layout.size5} ${tokens.layout.size8};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size1};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size4};
  }

  @media (${MainTheme.mediaQueries.small}) {
    gap: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.medium}) {
    gap: ${tokens.layout.size8};
  }
`;
