import { Stack } from '@jarvis/web-stratus-client';

const HP_ID_PROD_URL = 'https://account.id.hp.com';
const HP_ID__STG_URL = 'https://account.stg.cd.id.hp.com';

export const QUALTRICS_SURVEY_PROD_URL =
  'https://znaid9q7g0kclquju-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_aid9q7g0kClquJU';
export const QUALTRICS_SURVEY_TEST_URL =
  'https://zncuabwr7p241aiia-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_cuaBwr7P241aIia';

export const getHPIdUrl = () => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === Stack.prod ? HP_ID_PROD_URL : HP_ID__STG_URL;
};

export const getQualtricsSurveyUrl = (): string => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === Stack.prod
    ? QUALTRICS_SURVEY_PROD_URL
    : QUALTRICS_SURVEY_TEST_URL;
};

export const URLS = {
  HpIdSecurity: `${getHPIdUrl()}/security`,
  MobileTwoFactorAuth: `${getHPIdUrl()}/webcomponents/static/mfa.html?csrf=c309cf99-c771-4dee-8e99-ff26210fb8df_w2`
};
