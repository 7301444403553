import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  ManageAdvancedSecurityOptionsHyperlinkClicked,
  MobileTwoFactorAuthHyperlinkClicked,
  publishEvent
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';

const AdvancedSecurityOptions = () => {
  const { translate } = useDependencyManagerContext();

  const htmlString = translate(
    'security.advancedSecurityOptionsDescription',
    'Add an extra layer of security to your account using a one-time security code in addition to your password with {{tagReplaceStart}}mobile two-factor authentication{{tagReplaceEnd}}.'
  );
  const parts = htmlString.split(/{{\/?tagReplaceStart}}|{{\/?tagReplaceEnd}}/);

  return (
    <Card
      title={{
        content: `${translate(
          'security.advancedSecurityOptions',
          'Advanced security options'
        )}`,
        type: ROW_TYPE.EXTERNAL,
        link: {
          url: URLS.HpIdSecurity,
          dataTestId: 'AdvancedSecurityOptionsLink'
        },
        analyticsEvent: ManageAdvancedSecurityOptionsHyperlinkClicked
      }}
      enableRowsColorChange={false}
      data-testid="AdvancedSecurityOptions"
    >
      <div className="caption">
        {parts[0]}
        <a
          data-testid="MobileTwoFactorAuthHyperlink"
          href={URLS.MobileTwoFactorAuth}
          onClick={() => publishEvent(MobileTwoFactorAuthHyperlinkClicked)}
          target="_blank"
          rel="noreferrer"
        >
          {parts[1]}
        </a>
        {parts[2]}
      </div>
    </Card>
  );
};

export default AdvancedSecurityOptions;
