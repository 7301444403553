import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const VerifiedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.layout.size1};

  span {
    color: ${tokens.color.gray6};
  }
`;

export const StyledIcon = styled.div<{ verified: boolean }>`
  && {
    color: ${({ verified }) =>
      verified ? tokens.color.green7 : tokens.color.gray4};
    height: ${tokens.layout.size4};
    width: ${tokens.layout.size4};
  }
`;
