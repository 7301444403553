import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Security from 'src/pages/Security';
import SecurityDetails from 'src/pages/SecurityDetails';
import { getFeatureFlagsValues } from 'src/services/FeatureFlagsService';
import * as T from './../types';
import { ParentContainer } from './styles';

const MainComponent: React.FC<T.AppPropsType> = () => {
  const flags = getFeatureFlagsValues();

  return (
    <ParentContainer>
      <BrowserRouter>
        <Routes>
          <Route
            path="/:country/:language"
            element={<Security flags={flags} />}
          />
          <Route
            path="/:country/:language/security/:type"
            element={<SecurityDetails flags={flags} />}
          />
          <Route
            path="*"
            element={<Security flags={flags} />}
          />
        </Routes>
      </BrowserRouter>
    </ParentContainer>
  );
};

export default MainComponent;
