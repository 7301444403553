import { IconCheckCircle, IconHelp } from '@veneer/core';
import React from 'react';
import { StyledIcon, VerifiedContainer } from './styles';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

type VerifiedProps = {
  verified: boolean;
};

const Verified = ({ verified }: VerifiedProps) => {
  const { translate } = useDependencyManagerContext();
  const text = verified
    ? translate('security.verified', 'Verified')
    : translate('security.unverified', 'Unverified');

  return (
    <VerifiedContainer>
      <StyledIcon
        filled
        data-testid={verified ? 'IconCheckCircle' : 'IconHelp'}
        as={verified ? IconCheckCircle : IconHelp}
        verified={verified}
      />
      <span>{text}</span>
    </VerifiedContainer>
  );
};

export default Verified;
