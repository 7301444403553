import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  GenericFormContainer,
  Loading,
  useWebcomponent,
  WIDGETS_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { FormTypesEnum } from 'src/utils/enums';
import { getQualtricsSurveyUrl } from 'src/utils/urls';
import {
  publishEvent,
  SecurityDetailsScreenDisplayed
} from 'src/utils/analytics';
import { FlagProps } from 'src/types/Props';
import { Container } from '../Security/styles';
import { Center } from 'src/styles/styles';

interface SecurityDetailsProps {
  flags: FlagProps;
}

const SecurityDetails = ({ flags }: SecurityDetailsProps) => {
  const { translate, northboundAPIs } = useDependencyManagerContext();

  const navigation = northboundAPIs?.v1?.navigation;
  const country = northboundAPIs?.v1?.localization.country;
  const language = northboundAPIs?.v1?.localization.language;

  const handleRedirect = useCallback(() => {
    navigation?.push(`/${country}/${language}/security`);
  }, [country, language, navigation]);

  useWebcomponent({
    url: flags.isQualtricsSurveyEnabled ? getQualtricsSurveyUrl() : '',
    scriptType: 'text/javascript',
    scriptId: 'qualtrics-survey',
    target: 'head'
  });

  const { type } = useParams();

  useEffect(() => {
    if (!flags.isLoadingFlags) {
      const formTypes = [] as FormTypesEnum[];

      flags.isSecurityHpIdEmailEnabled && formTypes.push(FormTypesEnum.Email);
      flags.isSecurityHpIdPhoneEnabled && formTypes.push(FormTypesEnum.Phone);
      flags.isSecurityHpIdPasswordEnabled &&
        formTypes.push(FormTypesEnum.Password);

      if (type !== null && formTypes.includes(type as FormTypesEnum)) {
        publishEvent({
          ...SecurityDetailsScreenDisplayed,
          actionAuxParams: `type=${type}`
        });
        return;
      }

      handleRedirect();
    }
  }, [country, flags, handleRedirect, language, navigation, type]);

  const widgets: WIDGETS_TYPE[] = [];

  switch (type) {
    case FormTypesEnum.Email:
      widgets.push(WIDGETS_TYPE.CHANGE_EMAIL);
      break;
    case FormTypesEnum.Phone:
      widgets.push(WIDGETS_TYPE.PHONE);
      break;
    case FormTypesEnum.Password:
      widgets.push(WIDGETS_TYPE.CHANGE_PASSWORD);
      break;
    default:
      break;
  }

  if (flags.isLoadingFlags) {
    return (
      <Center>
        <Loading testid="loading-security-details" />
      </Center>
    );
  }

  return (
    <Container data-testid="security-details-page">
      <GenericFormContainer
        title={translate('security.loginDetails', 'Login details')}
        stack={northboundAPIs.v1.app.getAuthStack()}
        language={`${language}_${country.toUpperCase()}`}
        showButtons={false}
        widgets={widgets}
        labels={{
          cancel: translate('common.cancel', 'Cancel'),
          save: translate('common.save', 'Save')
        }}
        onClickArrowLeft={handleRedirect}
      />
    </Container>
  );
};

export default SecurityDetails;
