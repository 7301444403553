import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { ManageActiveSessionsHyperlinkClicked } from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';

const ActiveSessions = () => {
  const { translate } = useDependencyManagerContext();
  const text = translate(
    'security.activeSessionsDescription',
    'View and manage which devices have been recently used to sign in to your HP account.'
  );
  const title = {
    content: translate('security.activeSessions', 'Active sessions'),
    type: ROW_TYPE.EXTERNAL,
    link: {
      url: URLS.HpIdSecurity,
      dataTestId: 'ActiveSessionsLink'
    },
    analyticsEvent: ManageActiveSessionsHyperlinkClicked
  };

  return (
    <Card
      title={title}
      enableRowsColorChange={false}
      data-testid="ActiveSessions"
    >
      {text}
    </Card>
  );
};

export default ActiveSessions;
