import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

const breakpoints = {
  xsMin: 375, // 375px
  xsMax: tokens.layout.xsMax, // 575px
  smMin: tokens.layout.smMin, // 576px
  mdMin: tokens.layout.mdMin, // 768px
  mdMax: tokens.layout.mdMax, // 991px
  lgMax: tokens.layout.lgMax, // 1199px
  xlMin: tokens.layout.xlMin, // 1200px
  xxlMin: 1399, // 1399px
  xxlMax: 1400 // 1400px
};

export const MainTheme = {
  breakpoints,
  mediaQueries: {
    maxWidthMobile: `(max-width: ${breakpoints.xsMax})`,
    xsmall: `(max-width: ${breakpoints.xsMax})`,
    small: `(min-width: ${breakpoints.smMin}) and (max-width: ${breakpoints.mdMin})`,
    medium: `(min-width: ${breakpoints.mdMin}) and (max-width: ${breakpoints.mdMax})`,
    large: `(min-width: ${tokens.layout.lgMin}) and (max-width: ${breakpoints.lgMax})`,
    xLarge: `(min-width: ${breakpoints.xlMin}) and (max-width: ${breakpoints.xxlMin})`,
    xxLarge: `(min-width: ${breakpoints.xxlMax})`
  }
};

export const Section = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${tokens.layout.size4};
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: ${tokens.layout.size7};
  margin-bottom: ${tokens.layout.size1};
  align-items: center;

  @media (${MainTheme.mediaQueries.small}) {
    grid-template-columns: 1fr;
    gap: ${tokens.layout.size2};
  }
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size6};
  &:last-child {
    margin-bottom: ${tokens.layout.size2};
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  gap: ${tokens.layout.size5};

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    grid-template-columns: 1fr;
    gap: ${tokens.layout.size2};
  }
`;

export const RowSingleColumn = styled(Row)`
  grid-template-columns: 1fr;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  color: ${tokens.color.gray6};
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;

  && {
    @media screen and (${MainTheme.mediaQueries.maxWidthMobile}) {
      width: 271px;
    }
  }
`;

export const ValueContent = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Link = styled.div`
  display: flex;
  align-items: center;
  color: ${tokens.color.hpBlue6};
  padding-top: ${tokens.layout.size2};
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-end;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84vh;
`;
