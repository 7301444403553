import { IconTools } from '@veneer/core';
import { tokens } from '@veneer/tokens';
import styled from 'styled-components';

export const ParentContainer = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
`;

export const StyledIconTools = styled(IconTools)`
  && {
    color: ${tokens.color.gray6};
    height: ${tokens.layout.size8};
    width: ${tokens.layout.size8};
  }
`;
