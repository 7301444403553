import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const RowContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size6};
  padding: ${tokens.layout.size2} ${tokens.layout.size0};

  &:last-child {
    margin-bottom: ${tokens.layout.size2};
  }
`;
