import React from 'react';
import { useWebcomponent } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import ActiveSessions from 'src/components/ActiveSessions';
import AdvancedSecurityOptions from 'src/components/AdvancedSecurityOptions';
import LoginDetails from 'src/components/LoginDetails';
import { getQualtricsSurveyUrl } from 'src/utils/urls';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { FlagProps } from 'src/types/Props';
import { Container, Content, Header } from './styles';

interface SecurityProps {
  flags: FlagProps;
}

const Security = ({ flags }: SecurityProps) => {
  const { translate } = useDependencyManagerContext();

  useWebcomponent({
    url: flags.isQualtricsSurveyEnabled ? getQualtricsSurveyUrl() : '',
    scriptType: 'text/javascript',
    scriptId: 'qualtrics-survey',
    target: 'head'
  });

  return (
    <Container data-testid="security-page">
      <Header>
        <h4>{`${translate('menu.security', 'Security')}`}</h4>
        <p className="body">
          {`${translate(
            'security.description',
            'Manage your login details and what devices are used to log in to your HP account'
          )}`}
        </p>
      </Header>
      <Content>
        <LoginDetails flags={flags} />
        <AdvancedSecurityOptions />
        <ActiveSessions />
      </Content>
    </Container>
  );
};

export default Security;
