export enum FeatureFlagsEnum {
  qualtricsSurvey = 'qualtrics-survey',
  securityListLoginDetails = 'security-list-login-details',
  securityHPIDEmail = 'security-hp-id-email',
  securityHPIDPhone = 'security-hp-id-phone',
  securityHPIDPassword = 'security-hp-id-password'
}

export enum FormTypesEnum {
  Email = 'email',
  Phone = 'phone',
  Password = 'password'
}
